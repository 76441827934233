:root {
  --amplify-primary-color: #757575;
  --amplify-primary-tint: #BDBDBD;
  --amplify-primary-shade:#E0E0E0;
}

body {
  margin: 0;
}


.Navigator-item-23:hover, .Navigator-item-23:focus {
  background-color: rgba(255, 255, 255, 0.08);

}
.Navigator-item-23:visited, .Navigator-item-23:focus {

  color: #4fc3f7;
}